import { Auth } from 'aws-amplify';

const get_mandatory_key = (key) => {
  if (!Object.prototype.hasOwnProperty.call(process.env, key)) {
    throw (new Error(`Missing key ${key}`));
  }
  return process.env[key] || '';
};

// eslint-disable-next-line
const getEndpoint = (key) => {
  const endpoint = get_mandatory_key(key);
  if (!window.location.host.includes('localhost')) {
    return endpoint.replace(/\/\/[^:/]+/, `//${window.location.host}`);
  }
  return endpoint;
};

const config = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    mandatorySignIn: true,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_USER_POOL_DOMAIN,
      scope: ['email', 'profile', 'openid', 'phone', 'aws.cognito.signin.user.admin'],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: get_mandatory_key('REACT_APP_REST_ENDPOINT'),
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
          }
        }
      },
    ]
  },
  aws_appsync_graphqlEndpoint: get_mandatory_key('REACT_APP_APPSYNC_ENDPOINT'),
  aws_appsync_region: get_mandatory_key('REACT_APP_APPSYNC_REGION'),
  aws_appsync_authenticationType: process.env.REACT_APP_APPSYNC_AUTHENTICATION_TYPE,
};

export default config;
