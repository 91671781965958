import React, { Suspense, lazy } from 'react';

import Loader from '../common/Loader.component';
const PrivateApp = lazy(() => import('./PrivateApp'));

export default function AsyncPrivateApp() {
	return (
		<Suspense fallback={<Loader />}>
			<PrivateApp />
		</Suspense>
	);
}