import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Loading,
} from '@horizon/components-react';

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      },
  });

const Loader = () => {
    const classes = useStyles();
  return (
    <div className={classes.root}>
      <Loading>Loading...</Loading>
    </div>
  );
};

export default Loader;