import React, { useEffect, useState } from 'react';
import Amplify, { Auth, Hub, Logger } from 'aws-amplify';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { ApolloLink } from 'apollo-link';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import PrivateRoute from './components/route/PrivateRoute.component';
import PrivateApp from './components/app/AsyncPrivateApp';
import AuthContext from './components/auth/AuthContext.component';
import config from './config';

Amplify.configure(config);

const logger = new Logger("App");

const url = config.aws_appsync_graphqlEndpoint;
const region = config.aws_appsync_region;
const auth = {
  type: config.aws_appsync_authenticationType,
  jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
};

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const cache = new InMemoryCache();

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth })
]);

const client = new ApolloClient({
  link,
  cache,
  defaultOptions,
});


const App = () => {
  const [user, setUser] = useState(null);

  const getUser = () => {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          logger.info('auth event: ', event);
          getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          logger.info('auth event: ', event);
          console.log('Sign in failure', data);
          break;
        default:
          console.log('Event not supported:', data);
      }
    });
    getUser().then(userData => setUser(userData));
  }, []);

  return (
    <AuthContext.Provider value={user}>
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <PrivateRoute exact path="/" render={() => (<PrivateApp />)} />
          </Switch>
        </Router>
      </ApolloProvider>
    </AuthContext.Provider>
  );
}

export default App;
