import React, { useState, useEffect, useContext } from 'react';
import { Route } from 'react-router-dom';
import queryString from 'query-string';

import Loader from '../common/Loader.component';
import SignIn from '../auth/SignIn.component';
import AuthContext from '../auth/AuthContext.component';


const PrivateRoute = ({ render: Component, ...rest }) => {
    const user = useContext(AuthContext);
    const { code } = queryString.parse(window.location.search);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user !== undefined && !code) {
            setLoading(false);
        }
    }, [user, code]);

    return (
        loading
            ? <Loader />
            : (
                <Route
                    {...rest} // eslint-disable-line react/jsx-props-no-spreading
                    render={() => (
                        user ? <Component /> : <SignIn />
                    )}
                />
            ));
};

export default PrivateRoute;
